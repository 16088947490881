@use "variables" as *;
@use "mixins" as mixins;

.text-truncate {
  @include mixins.text-truncate;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.font-weight-normal {
  font-weight: $font-weight-normal !important;
}
.font-weight-bold {
  font-weight: $font-weight-semi-bold !important;
}
