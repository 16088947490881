@use "colors" as *;
@use "sass:math";

// Links
$link-color: $primary;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;

// Paragraphs
$paragraph-margin-bottom: 1rem;

// Fonts
$font-family-serif: "Roboto Slab", Georgia, Times, "Times New Roman", serif;
$font-family-sans-serif: "Montserrat", Roboto, "Segoe UI", Arial, sans-serif,
  "Segoe UI Emoji";
$font-family-monospace: "Roboto Mono", Consolas, monaco, monospace;
$font-family-base: $font-family-sans-serif;

$font-size-base: 1rem; // 16px
$font-size-xxlg: ($font-size-base * 2.5) !default; // 40px
$font-size-xlg: ($font-size-base * 2) !default; // 32px
$font-size-lg: ($font-size-base * 1.5) !default; // 24px
$font-size-md: ($font-size-base * 1.25) !default; // 20px
$font-size-xmd: ($font-size-base * 1.1) !default; // 18px
$font-size-sm: ($font-size-base * 0.875) !default; // 14px
$font-size-xs: ($font-size-base * 0.75) !default; // 12px
$font-size-xxs: ($font-size-base * 0.65) !default; // 10px

$font-size-responsive-body: clamp(0.75rem, 1.5vw, 1rem);

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: 800 !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.3 !default;

$text-muted: $gray-400 !default;
$caret-width: 0.3em !default;
$list-inline-padding: 0.5rem !default;
$spacer: 1rem !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$headings-margin-bottom: math.div($spacer, 2) !default;
$headings-font-family: inherit !default;
$headings-font-weight: 600 !default;
$headings-line-height: 1.2 !default;
$headings-color: inherit !default;
