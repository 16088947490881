@use "variables" as *;

@mixin highlight-numbers {
  font-family: $font-family-base;
  font-size: 100px;
  letter-spacing: -4px;
  text-align: left;
  color: $gray-600;
}

@mixin highlight-paragraph {
  font-family: $font-family-base;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.6;
  text-align: left;
  color: $gray-600;
}

@mixin text-style {
  font-family: $font-family-base;
  font-size: 16px;
  line-height: 1.75;
  text-align: left;
  color: $gray-600;
}

@mixin text-style-small {
  font-family: $font-family-base;
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  color: $Legendas;
}

@mixin text-link-style-small {
  color: $blue;
  font-weight: $font-weight-normal;
  font-size: $font-size-xs;
  line-height: $line-height-base;
  font-family: $font-family-base;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-decoration: none;
}

@mixin word-wrap() {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@mixin ellipsis($width) {
  max-width: $width;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin font-style {
  font-size: 1.25rem;
  font-weight: $font-weight-semi-bold;
}

@mixin small-font-style {
  font-size: 0.75rem;
  color: $gray-600;
}

@mixin skeleton-load($color1, $color2, $color3) {
  background: linear-gradient(to right, $color1, $color2, $color3);
  background-size: 200%;
  background-position: left;
  animation: skeleton-animation 1s ease-out 0s infinite normal;
}

@keyframes skeleton-animation {
  0%,
  100% {
    background-position: right;
  }

  50% {
    background-position: left;
  }
}
