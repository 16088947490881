@use "styles/index" as *;

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1,
.h1 {
  font-size: $h1-font-size;
}

h2,
.h2 {
  font-size: $h2-font-size;
}

h3,
.h3 {
  font-size: $h3-font-size;
}

h4,
.h4 {
  font-size: $h4-font-size;
}

h5,
.h5 {
  font-size: $h5-font-size;
}

h6,
.h6 {
  font-size: $h6-font-size;
}

p {
  font-size: $font-size-base;
}

.list-unstyled {
  @include list-unstyled;
}

.list-inline {
  @include list-unstyled;
}

.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}

* {
  box-sizing: border-box;
  font-family: $font-family-base;
}

html,
body {
  margin: 0;
  height: 100%;
  font-size: $font-size-base;
  font-family: $font-family-base;
  color: $body-color;
  background-color: $body-bg;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

strong {
  font-weight: $font-weight-semi-bold;
}

app-root {
  flex: 1;
  min-height: 100vh;
  flex-direction: column;
  display: flex;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.mat-mdc-card {
  padding: 1rem;
}

.mat-mdc-radio-button {
  .mdc-form-field {
    .mdc-label {
      font-size: 1rem;
    }
  }
}

.mdc-button {
  .mdc-button__label {
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: normal;
  }
}

.mat-mdc-dialog-container {
  .mdc-dialog__surface {
    overflow: hidden;
    padding: 1rem;
  }
}

.mat-mdc-snack-bar-container.success {
  .mdc-snackbar__surface {
    background-color: $success;

    .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
      color: $white;
    }
  }
}

.mat-mdc-snack-bar-container.error {
  .mdc-snackbar__surface {
    background-color: $danger;

    .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
      color: $white;
    }
  }
}

.mat-mdc-snack-bar-container.warning {
  .mdc-snackbar__surface {
    background-color: $warning;

    .mat-mdc-snack-bar-label {
      .mat-mdc-snack-bar-label {
        color: $black;
      }
    }

    .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
      color: $black;
    }
  }
}

.mat-mdc-snack-bar-container.info {
  .mdc-snackbar__surface {
    background-color: $info;
  }

  .mat-mdc-snack-bar-label {
    .mat-mdc-snack-bar-label {
      color: $white;
    }
  }

  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: $white;
  }
}

.mat-mdc-snack-bar-container.message {
  .mdc-snackbar__surface {
    background-color: $blue;
  }

  .mat-mdc-snack-bar-label {
    .mat-mdc-snack-bar-label {
      color: $white;
    }
  }

  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: $white;
  }
}

.privacy {
  position: relative;

  .privacy-text-on {
    display: none;
  }

  .privacy-text-off {
    display: inline;
  }

  [class^="privacy-text-"] ~ span {
    display: inline;
  }

  mat-icon {
    margin-right: 0.25rem;
    position: relative;
  }

  mat-icon:first-of-type {
    display: none;
  }
}

mat-radio-button label span.mat-radio-label-content {
  white-space: normal;
  word-break: break-all;
}

.privacy-on {
  &.privacy {
    .privacy-text-on {
      display: inline;
    }

    .privacy-text-off {
      display: none;
    }

    mat-icon:first-of-type {
      display: inline-block;
    }

    mat-icon:last-of-type {
      display: none;
    }
  }

  .privacy {
    .privacy-text-on {
      display: inline-block;
    }

    .privacy-text-off {
      display: none;
    }

    mat-icon:first-of-type {
      display: inline-block;
    }

    mat-icon:last-of-type {
      display: none;
    }
  }
}

.mat-mdc-tooltip {
  font-size: $font-size-xs;
}

.mat-mdc-tab-list {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
